<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row class="mx-2" no-gutters>
      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <!-- Link component -->
          <v-btn
            v-if="study"
            class="btn-primary"
            color="primary"
            @click="toggleComponentLinkDialog(true)"
          >
            {{ $t("studies.components.link.title") }}
          </v-btn>

          <!-- New component -->
          <v-btn
            class="btn-primary"
            color="primary"
            @click="setShowComponentAddOrEditDialog(true)"
          >
            {{ $t("studies.components.add.title") }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Datatable -->
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          multi-sort
          sort-by="createdAt"
          :sort-desc="false"
          :footer-props="{ 'items-per-page-options': itemsPerPage }"
          class="elevation-1"
        >
          <!-- Name -->
          <template v-slot:item.name="{ item }">
            <router-link :to="{ name: 'Component', params: { id: item.id } }">
              {{ item.name }}
            </router-link>
          </template>

          <!-- Public Name -->
          <template v-slot:item.publicName="{ item }">
            {{ item.publicName }}
          </template>

          <!-- Type -->
          <template v-slot:item.type="{ item }">
            {{ item.type }}
          </template>

          <!-- Category -->
          <template v-slot:item.category="{ item }">
            {{ item.category }}
          </template>

          <!-- Supplier -->
          <template v-slot:item.suppliers="{ item }">
            <template v-if="item.suppliers.length > 0">
              <template v-for="supplier of item.suppliers">
                <router-link
                  :key="supplier.id"
                  :to="{ name: 'Supplier', params: { id: supplier.id } }"
                >
                  {{ supplier.name }}
                </router-link>
              </template>
            </template>

            <span v-else>{{ $t("common.misc.NA") }}</span>
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{ item }">
            <!-- Unlink -->
            <v-btn
              icon
              :loading="
                currentItem &&
                currentItem.id === item.id &&
                currentItemLoadingBtn
              "
              @click="unlinkComponent(item)"
            >
              <v-icon small>fas fa-unlink</v-icon>
            </v-btn>

            <!-- Delete component -->
            <v-btn
              icon
              :loading="
                currentItem &&
                currentItem.id === item.id &&
                currentItemLoadingBtn
              "
              @click="setShowDeleteComponentDialog(true, item)"
            >
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main dialog -->
    <ComponentMainDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :account="product ? product.account : null"
      :product="product"
      :fm_referent="study ? study.fm_referent : null"
      :project-impact="study ? study.projectImpact : null"
      :study="study"
      :supplier="supplier"
      @close="
        (needRefresh) =>
          setShowComponentAddOrEditDialog(false, null, needRefresh)
      "
    />

    <!-- Link component dialog -->
    <ProductComponentLinkDialog
      v-if="showDialog.linkComponent"
      :value="showDialog.linkComponent"
      :study="study"
      @close="(needRefresh) => toggleComponentLinkDialog(false, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :label="currentItem.name"
      :text="$t('components.deleteDialog.text')"
      @submit="deleteComponent"
      @close="setShowDeleteComponentDialog(false)"
    />
  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";

export default {
  name: "ComponentsTab",
  components: {
    ProductComponentLinkDialog: () =>
      import("@/components/Studies/Components/ProductComponentLinkDialog"),
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    ComponentMainDialog: () =>
      import("@/components/Common/Components/ComponentMainDialog"),
  },
  mixins: [vuetifyDataTable],

  props: {
    supplier: { type: Object, default: () => null },
    product: { type: Object, default: () => null },
    study: { type: Object, default: () => null },
  },

  data() {
    return {
      headers: [
        {
          text: this.$t("components.tab.datatable.headers.name"),
          value: "name",
          sortable: true,
          width: "20%",
        },
        {
          text: this.$t("components.tab.datatable.headers.publicName"),
          value: "publicName",
          sortable: false,
          width: "20%",
        },
        {
          text: this.$t("components.tab.datatable.headers.type"),
          value: "type",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("components.tab.datatable.headers.category"),
          value: "category",
          sortable: false,
          width: "10%",
        },
        {
          text: this.$t("components.tab.datatable.headers.suppliers"),
          value: "suppliers",
          sortable: false,
          width: "20%",
        },

        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%",
        },
      ],
      URL_API: {
        GET_ALL: "/components",
      },

      filters: {
        studies: this.study ? [this.study.id] : [],
        suppliers: this.supplier ? [this.supplier.id] : [],
      },

      showDialog: {
        addOrEdit: false,
        linkComponent: false,
        delete: false,
      },

      currentItem: null,
      currentItemLoadingBtn: false,
    };
  },

  methods: {
    setShowComponentAddOrEditDialog(
      val,
      currentItem = null,
      needRefresh = false
    ) {
      this.currentItem = currentItem;
      if (needRefresh) this.searchTrigger();
      this.showDialog.addOrEdit = val;
    },

    setShowDeleteComponentDialog(val, currentItem = null, needRefresh = false) {
      this.currentItem = currentItem;
      if (needRefresh) this.searchTrigger();
      this.showDialog.delete = val;
    },

    toggleComponentLinkDialog(val, needRefresh = false) {
      if (needRefresh) this.searchTrigger();
      this.showDialog.linkComponent = val;
    },

    unlinkComponent(item) {
      this.currentItem = item;
      this.currentItemLoadingBtn = true;

      this.$http
        .delete(`/studies/${this.study.id}/components/${item.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.searchTrigger();
        })
        .catch((err) => {})
        .finally(() => {
          this.currentItem = null;
          this.currentItemLoadingBtn = false;
        });
    },

    deleteComponent() {
      this.currentItemLoadingBtn = true;
      this.$http
        .delete(`/components/${this.currentItem.id}`, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.setShowDeleteComponentDialog(false, null, true);
        })
        .catch((err) => {})
        .finally(() => {
          this.currentItemLoadingBtn = false;
        });
    },
  },
};
</script>

<style scoped></style>
